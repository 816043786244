<template>

<div>

  <!-- Title and Controls-->
  <div class="d-flex justify-space-between align-center">
    <div @click="expand"  class="d-flex align-center pointer">
      <v-icon :color="wsACCENT">mdi-menu-{{ displayContent ? 'down' : 'right' }}</v-icon>
      <h4  class="wsDARKER linkHover pointer">
        {{ $t(title) }}
      </h4>
    </div>

    <accordion-controls-menu
        v-if="displayContent"
        v-model="value"
        :value="value"
        @input="$emit('input', value)"
        :type="type"
        :lang="lang"
        @edit-template="editTemplateTrigger++"
        :enable-edit-trigger="enableEditTrigger"
        :choose-template-trigger="chooseTemplateTrigger"
        :specialty-id="specialtyId"
    />

  </div>

  <!-- Main Content -->
  <v-expand-transition>

    <!-- Content -->
    <div v-if="displayContent" class="pt-5">
      <description-items-editor
          v-if="value.uuid"
          :value="value.content"
          @input="updateContent"
          :template-name="value.name"
          :confirm-delete-text="confirmDeleteText"
          :add-item-trigger="addItemTrigger"
          :blank-element="blankElement"
          :edit-template-trigger="editTemplateTrigger"
          @enable-edit="enableEditTrigger++"
          :lang="lang"
          :uuid="value.uuid"
      >
        <!--    -->
        <template #item="{item}">
          <slot name="item" :item="item"></slot>
        </template>
        <template #editor="{entityData}">
          <slot name="editor" :entityData="entityData"></slot>
        </template>

      </description-items-editor>

      <!-- No Content Menu-->
      <div v-else style="background-color: var(--wsLIGHTCARD);" class="wsRoundedLight pa-6 py-12">
        <h4 class="text-center wsACCENT">
          {{ $t('Не має даних') }}
        </h4>
        <h5 class="text-center font-weight-regular wsACCENT mt-2">
          {{ $t('Оберіть шаблон або створіть новий') }}
        </h5>

        <div class="d-flex align-center justify-center mt-5">
          <ws-button @click="chooseTemplateTrigger++" label="Choose" :color="wsACCENT" outlined left-icon="mdi-folder-open-outline"
            :disabled="LOADING"
          />
          <ws-button @click="createNewAccordion" left-icon="mdi-plus" label="Create" class="ml-2"
            :disabled="LOADING"
          />
        </div>

      </div>
    </div>
  </v-expand-transition>

</div>



</template>



<script>
import accordionControlsMenu from "@modules/wsu_structure/components/description/accordionControlsMenu.vue";
import descriptionItemsEditor from "@modules/wsu_structure/components/description/descriptionItemsEditor.vue";
import {mapActions} from "vuex";

export default {
  name: "specialtyAccordionInput",
  components: {descriptionItemsEditor, accordionControlsMenu},
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    title : {
      type : String,
    },
    type : {
      type : String,
    },
    lang : {
      type : String,
      default : 'ua'
    },
    blankElement : {
      type : Object
    },
    confirmDeleteText : {
      type : String,
    },
    specialtyId : {
      type : String,
    }
  },
  data() {
    return {
      displayContent : false,
      addItemTrigger : 0,
      editTemplateTrigger : 0,
      enableEditTrigger : 0,
      chooseTemplateTrigger : 0,
    }
  },
  watch : {
    value : {
      handler() {
        if (!this.EQ(this.value, this.items)) {
          if (this.value && this.value.content) {
            this.items = this.COPY(this.value.content)
          }
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('wsu_structure',['ADD_SPECIALTY_ACCORDION']),

    expand() {
      this.displayContent = !this.displayContent
      this.$emit('expand', this.displayContent)
    },

    async createNewAccordion() {
      let data = {
        specialty_id : this.specialtyId,
        lang : this.lang,
        type : this.type
      }
      let result = await this.ADD_SPECIALTY_ACCORDION(data)
      this.$emit('input', result)
      setTimeout(()=> {
        this.addItemTrigger++
      },100)
    },
    updateContent(item) {
      let data = this.COPY(this.value)
      data.content = item
      this.$emit('input', this.COPY(data))
    }
  },
  mounted() {
    if (this.value && this.value.content) {
      this.items = this.COPY(this.value.content)
    }
  }

}
</script>

<style scoped>

</style>